import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, CircularProgress, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { searchProductDetails } from "src/api/products";
import { deliveryRequestStyle } from "src/styles/deliveryRequestStyle";
import { useDeliveryRequest } from "../context/deliveryRequestContext";
import emptyCartIcon from "../svg/empty_cart.svg";
import CartItem from "./cart_item";

export const AddToCart = () => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null); // Separate state to control selected value
  const [cartItems, setCartItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useDeliveryRequest();

  // Function to update global cart state
  const updateGlobal = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: cartItems.map((item) => ({
        productVariantId: item.productVariantId,
        quantity: item.quantity
      }))
    });
  };

  const decreaseQty = (productVariantId) => {
    cartItems.find((item) => item.productVariantId === productVariantId).quantity--;
    const updatedCartItems = cartItems.filter((item) => item.quantity > 0);
    setCartItems([...updatedCartItems]);
    dispatch({
      type: "ADD_TO_CART",
      payload: updatedCartItems.map((item) => ({
        productVariantId: item.productVariantId,
        quantity: item.quantity
      }))
    });
  };

  const increaseQty = (productVariantId) => {
    cartItems.find((item) => item.productVariantId === productVariantId).quantity++;

    setCartItems([...cartItems]);

    updateGlobal();
  };

  const removeItem = (productVariantId) => {
    const updatedCartItems = cartItems.filter((item) => item.productVariantId !== productVariantId);
    setCartItems([...updatedCartItems]);

    dispatch({
      type: "ADD_TO_CART",
      payload: updatedCartItems.map((item) => ({
        productVariantId: item.productVariantId,
        quantity: item.quantity
      }))
    });
  };

  // Debounced function to fetch product options based on user input
  const debouncedFetchProducts = useCallback(
    debounce(async (input) => {
      if (input.length >= 3) {
        setLoading(true);
        try {
          const result = await searchProductDetails(10, 0, input);
          if (result?.result?.data?.products) {
            setSearchResults(result.result.data.products);
          } else {
            setSearchResults([]);
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
          setSearchResults([]);
        }
        setLoading(false);
      } else {
        setSearchResults([]);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (inputValue.length >= 3) {
      debouncedFetchProducts(inputValue);
    } else {
      setSearchResults([]);
    }
  }, [inputValue, debouncedFetchProducts]);

  // Handle selecting a product from Autocomplete
  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption) {
      const selectedProduct = searchResults.find((product) => `${product.brand.name} ${product.product.name} - ${product.unit.name}` === selectedOption);

      if (selectedProduct) {
        const productWithQuantity = { ...selectedProduct, quantity: 1 };
        const existsInCart = cartItems.find((item) => item.productVariantId === productWithQuantity.productVariantId);

        if (existsInCart) {
          const updatedCartItems = cartItems.map((item) => {
            if (item.productVariantId === productWithQuantity.productVariantId) {
              return { ...item, quantity: item.quantity + 1 };
            }
            return item;
          });
          dispatch({
            type: "ADD_TO_CART",
            payload: updatedCartItems.map((item) => ({
              productVariantId: item.productVariantId,
              quantity: item.quantity
            }))
          });
          setCartItems(updatedCartItems);
        } else {
          const updatedCart = [...cartItems, productWithQuantity];
          dispatch({
            type: "ADD_TO_CART",
            payload: updatedCart.map((item) => ({
              productVariantId: item.productVariantId,
              quantity: item.quantity
            }))
          });
          setCartItems(updatedCart);
        }
      }
    }

    // Clear both inputValue and selected value to reset the Autocomplete field
    setInputValue("");
    setValue(null);
  };

  const renderHighlightedText = (name, searchTerm) => {
    if (!searchTerm) return name;

    const regex = new RegExp(`(${searchTerm})`, "i");
    const parts = name.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <Box key={index} component="span" sx={{ fontWeight: "bold", display: "inline" }}>
            {part}
          </Box>
        );
      } else {
        return (
          <Box key={index} component="span" sx={{ fontWeight: "normal", display: "inline" }}>
            {part}
          </Box>
        );
      }
    });
  };

  return (
    <Container
      sx={{
        mt: 1,
        backgroundColor: "#F2F2F2",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px"
      }}
    >
      <Typography style={deliveryRequestStyle.cartTitle}>Cart</Typography>

      <Grid
        item
        xs={12}
        sx={{
          mb: 2,
          mt: 2,
          flexGrow: 1,
          gap: "24px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Autocomplete
          freeSolo
          id="product-search"
          disableClearable
          options={searchResults.map((option) => `${option.brand.name} ${option.product.name} - ${option.unit.name}`)}
          loading={loading}
          inputValue={inputValue}
          value={value}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, value) => {
            handleOptionSelect(event, value);
          }}
          renderOption={(props, option, { index }) => {
            console.log({ props });
            delete props.className;
            return (
              <div className={`bg-white w-full p-[16px] flex items-center cursor-pointer h-[62px] ${index < searchResults.length - 1 ? "border-b border-b-[#D9D9D9]" : ""}`} {...props}>
                <img src={searchResults[index]?.productVariantImage?.[0]?.imageUrl} alt={searchResults[index]?.product?.name} className="w-[30px] h-[30px] rounded-[8px]" />
                <p className="ml-[8px] text-[14px] font-normal">{renderHighlightedText(option, inputValue)}</p>
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "8px"
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </>
                )
              }}
            />
          )}
        />
        {cartItems.length === 0 ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1
            }}
          >
            <img src={emptyCartIcon} alt="empty cart" style={{ width: "160px", height: "160px" }} />
            <Typography style={deliveryRequestStyle.emptyCartText}>Your cart is empty</Typography>
            <Typography style={deliveryRequestStyle.cartInstruction}>Search and add items from above to create an order</Typography>
          </Grid>
        ) : (
          <div className="flex flex-col items-start justify-start gap-[24px] w-full">
            <div className="flex flex-col gap-[16px] items-start justify-start w-full">
              {cartItems.map((item) => (
                <CartItem item={item} removeItem={removeItem} increaseQty={increaseQty} decreaseQty={decreaseQty} key={item.productVariantId} />
              ))}
            </div>
            <button type="submit" className="bg-[#EF7B22] text-white rounded-[8px] font-bold flex justify-center items-center h-[52px] w-full">
              <p>Create New Request</p>
            </button>
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default AddToCart;
