import { Helmet } from "react-helmet-async";
import { useState, useEffect, useContext } from "react";
import { CSVDownload } from "react-csv";
import TableCell from "@mui/material/TableCell";
// @mui
import { Button, CircularProgress, Container, Divider, Grid, InputAdornment, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
// components

import { addVariantButton, pTable } from "../../styles/styles";

// eslint-disable-next-line
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { getComparator, stableSort } from "src/utils/sortTables";
import { Search } from "@mui/icons-material";
import { fetchUserData, fetchUserDetails } from "src/api/users";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { debounce } from "lodash";
import { colors } from "src/constants/constants";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "src/utils/utils";
import CRMDetails from "./CRMDetails";
import { statusCodes } from "src/utils/statusCodeUtils";
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function CRMPage() {
  //Handle search persist
  const searchQuery = useQuery().get("search") || null;

  const [tableArray, setTableArray] = useState([]);
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [exportt, setExport] = useState(false);
  const [exportTable, setExportTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    dispatch,
    state: { crmState, alertState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    try {
      if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
      updateTableData(searchText, pageSize, page);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
    // eslint-disable-next-line
  }, [crmState.crmDetails, searchText]);

  const updateTableData = async (searchText, pageSize, page) => {
    dispatch({ type: "clear" });
    setLoading(true);
    const response = await fetchUserData(searchText, pageSize, page);
    if (response.httpStatusCode === 200) {
      const { pageNumber, pageSize, totalCount } = response.result.data;
      const array = [];
      response.result.data.users.forEach((data) => {
        const { userFistName, userLastName, userRegiseredNumber, address, role, userId } = data;
        const body = {
          userId,
          customerName: `${userFistName} ${userLastName}`,
          address: address.length === 0 ? "-" : `${address[0].landMark ? address[0].landMark : ""}${address[0].landMark && address[0].street ? ", " : ""}${address[0].street ? address[0].street : ""}`,
          userRegiseredNumber,
          role
        };
        array.push(body);
      });

      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalCount);

      setSearchParams({ ...searchParams, search: searchText });

      setLoading(false);
    } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
      setLoading(false);
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
    } else {
      setLoading(false);
      alert(response.message);
    }
  };

  const headCells = [
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "Customer Name"
    },
    {
      id: "address",
      numeric: false,
      disablePadding: true,
      label: "Address"
    },
    {
      id: "userRegiseredNumber",
      numeric: false,
      disablePadding: true,
      label: "Registered Number"
    },
    {
      id: "role",
      numeric: false,
      disablePadding: true,
      label: "Role"
    }
  ];

  const fetchExportData = async (pageSize) => {
    const response = await fetchUserData(null, pageSize, 0);
    if (response.httpStatusCode === 200) {
      const array = [];
      response.result.data.users.forEach((data) => {
        const { userFistName, userLastName, userRegiseredNumber, address, role, userId } = data;
        const body = {
          userId,
          customerName: `${userFistName} ${userLastName}`,
          address: address.length === 0 ? "-" : `${address[0].landMark}, ${address[0].street}`,
          userRegiseredNumber,
          role: role[role.length - 1]
        };
        array.push(body);
      });
      setExportTable(array);
      if (exportt) {
        setExport(false);

        setTimeout(() => {
          setExport(true);
        }, 500);
      } else {
        setExport(true);
      }
      console.log(array);
    } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
    } else {
      alert(response.message);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeSearch = (e) => {
    if (!e.target.value.trim()) {
      setSearchText(null);
    } else if (e.target.value < 3) {
      setSearchText(e.target.value);
    } else {
      setSearchParams({ search: e.target.value });
      setSearchText(e.target.value);
    }
  };

  const headers = [
    { label: "Customer ID", key: "userId" },
    { label: "Customer Name", key: "customerName" },
    { label: "Address", key: "address" },
    { label: "Registered Number", key: "userRegiseredNumber" },
    { label: "Role", key: "role" }
  ];

  const csvReport = {
    data: exportTable,
    headers,
    filename: `CRM.csv`
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: CRM </title>
      </Helmet>
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ py: 2 }}>
              CRM
            </Typography>
          </Grid>
          {crmState.crmDetails ? null : (
            <Grid item xs={8} style={headerStyle.searchContainer}>
              <TextField
                onChange={debounce((e) => {
                  handleChangeSearch(e);
                }, 100)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    updateTableData(searchText, 10, 0);
                  }
                }}
                placeholder="Search User"
                defaultValue={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: headerStyle.searchTextField,
                  inputProps: {
                    style: headerStyle.placeHolderText
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        {/*Subheader Container */}
        <Grid sx={{ my: 1 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderArrowContainer, justifyContent: "space-between" }}>
          {crmState.crmDetails ? (
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  dispatch({ type: "clear" });
                }}
              >
                <img alt="add" src="/assets/icons/button/back.svg" />
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              {" "}
            </Grid>
          )}

          <Grid item xs={4}>
            <Button disabled={crmState.crmDetails} onClick={() => fetchExportData(totalProductsCount)} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
              Export
            </Button>
            {exportt ? <CSVDownload {...csvReport} /> : null}
          </Grid>
        </Grid>

        <Grid maxWidth="xxl">
          {crmState.crmDetails ? (
            <CRMDetails />
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} style={{ whiteSpace: "nowrap" }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress sx={{ color: colors.primary }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                        <TableRow key={record.userId}>
                          {/* eslint-disable-next-line */}
                          <TableCell align="left">
                            <p style={pTable}>
                              {" "}
                              <Link to={`/dashboard/crm/details/${record.userId}${searchText ? `?search=${searchText}` : ""}`}>{record.customerName}</Link>
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.address}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.userRegiseredNumber}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.role === 1 ? "Admin" : record.role === 2 ? "Tushop Keeper" : record.role === 3 ? "Customer" : "-"}</p>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ height: "20px" }}>
                <TablePagination
                  rowsPerPageOptions={[2, 10, 15, 20, 25]}
                  component="div"
                  count={totalProductsCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newVal) => {
                    updateTableData(searchText, pageSize, newVal);
                  }}
                  onRowsPerPageChange={(e) => {
                    updateTableData(searchText, e.target.value, page);
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
