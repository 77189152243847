export const JIPANGE = {
  JIPANGE_CASHBACK_VALUE: process.env.REACT_APP_JIPANGE_CASHBACK_VALUE,
  JIPANGE_CASHBACK_ORDER_THRESHOLD: process.env.REACT_APP_JIPANGE_CASHBACK_ORDER_THRESHOLD,
  JIPANGE_PRICE_CHANGE_THRESHOLD: process.env.REACT_APP_JIPANGE_PRICE_CHANGE_THRESHOLD,
  SEND_JIPANGE_PRICE_CHANGE_NOTIFICATION: process.env.REACT_APP_SEND_JIPANGE_PRICE_CHANGE_NOTIFICATION
};

export const pamojaType = {
  userPamoja: 0,
  superMoja: 1
};

export const allPamojaStatus = {
  inActive: 0,
  active: 1,
  draft: 2
};

export const pamojaChangeStatus = {
  all: 0,
  active: 1,
  inActive: 2,
  draft: 3
};

export const pamojaCsvExports = {
  ignore: 0,
  productSpecific: 1,
  pamojaSpecific: 2,
  pamojaUserSpefic: 3,
  pamojaOrders: 4
};

export const colors = {
  primary: "#FE7C19",
  neutral: "#8C8C8C",
  secondaryNeutral: "#404040"
};

export const PROMO_CHANNEL = {
  product: {
    id: 1,
    name: "Product"
  },
  sokoCart: {
    id: 2,
    name: "Cart"
  },
  brand: {
    id: 3,
    name: "Brand"
  }
};

export const PROMO_OFFER_TYPE = {
  discount: {
    id: 1,
    name: "Discount"
  },
  product: {
    id: 2,
    name: "Product"
  },
  freeDelivery: {
    name: "Free Delivery",
    id: 3
  }
};

export const VISIBILITY_TYPE = {
  private: {
    id: 1,
    name: "Private"
  },
  inApp: {
    id: 2,
    name: "InApp"
  },
  external: {
    id: 3,
    name: "External"
  }
};

export const PROMO_STATUS = {
  inActive: 0,
  active: 1,
  expired: 2,
  completed: 3
};

export const VALIDITY_TYPE = {
  timePeriod: { id: 1, name: "Time Period" },
  redemptionLimit: { id: 2, name: "Redemption Limit" }
};

export const PURCHASE_TYPES = {
  tushop: 1,
  market: 2,
  pamoja: 3,
  jipange: 4,
  supermoja: 5,
  jumbo: 6
};

export const ORDER_STATUS = {
  1: "#FF4C2A",
  2: "#005AFF",
  3: "#0BFBFBF",
  4: "#FFA100",
  5: "#FFA100",
  6: "#2FAA73"
};

export const ORDER_STATUS_NAME = {
  1: "Order Pending",
  2: "Order Placed",
  3: "Cancelled",
  4: "InTransit",
  5: "Ready For Pickup",
  6: "Delivered"
};

export const PAYMENT_STATUS = {
  SUCCESS: "#2FAA73",
  FAILURE: "#FF4C2A",
  CANCELLED: "#0BFBFBF",
  PENDING: "#005AFF"
};

export const PRODUCT_VIEW = {
  catalogue: 1,
  categories: 2
};

export const CATEGORY_TYPE = {
  primaryCategory: 1,
  subCategory: 2
};

export const CATEGORY_STATUS = {
  inActive: 0,
  active: 1
};

export const SWITCH_TYPE = {
  categorySwitch: 1,
  subCategorySwitch: 2
};

export const urlType = {
  image: 1,
  video: 2
};

export const filterStatus = {
  active: 1,
  inActive: 0,
  all: 3
};

export const orderingPlatform = {
  web: 1,
  app: 2
};

export const userRoles = {
  seller: 4
};
